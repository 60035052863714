import React from "react";
import styled from "@emotion/styled";
import { ClearedStyleRouterLink } from "@modules/Components";
import { IoIosArrowBack } from "react-icons/io";
import SellHeaderText from "./SellHeaderText";

const BackButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 904px 1fr;
  height: 34px;

  @media (max-width: 1050px) {
    grid-template-columns: 80px 1fr 80px;
  }
  @media (max-width: 850px) {
    height: auto;
  }
`;

const BackButton = styled(ClearedStyleRouterLink)`
  font-size: 12px;
  font-weight: bold;
  line-height: 2;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-end;
  margin-right: 63px;

  @media (max-width: 1150px) {
    margin-right: 0px;
    justify-self: flex-start;
  }
  @media (max-width: 850px) {
    display: none;
  }
`;

const BackText = styled.span`
  margin-left: 4px;
`;

const SellHeaderBackButton = () => {
  return (
    <BackButtonContainer>
      <BackButton to="/sell-gift-cards/">
        <IoIosArrowBack
          style={{
            color: "rgba(255, 255, 255, 0.5)",
            fontSize: "22px",
            marginLeft: "21px"
          }}
        />
        <BackText>Back</BackText>
      </BackButton>
      <SellHeaderText />
      <div></div>
    </BackButtonContainer>
  );
};

export default SellHeaderBackButton;

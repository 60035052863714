import React from "react";
import styled from "@emotion/styled";

const NewsLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  max-width: 90%;
  margin: 0 auto;

  @media (max-width: 850px) {
    grid-column: 1/4;
  }
  @media (max-width: 600px) {
    max-width: 354px;
    margin: 0 auto;
    text-align: center;
  }
  @media (max-width: 385px) {
    max-width: 330px;
  }
  @media (max-width: 350px) {
    max-width: 307px;
  }
  @media (max-width: 320px) {
    max-width: 90%;
  }
`;

const NewsText = styled.div`
  font-size: 14px;
  height: 100%;
  margin-left: 21px;

  @media (max-width: 850px) {
    margin: 0px;
  }
  @media (max-width: 385px) {
    font-size: 13px;
  }
  @media (max-width: 350px) {
    font-size: 12px;
  }
  @media (max-width: 315px) {
    font-size: 11px;
  }
`;

const TextSpan = styled.span`
  border-radius: 20.5px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  font-weight: 600;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding-right: 18px;
  padding-left: 18px;

  @media (max-width: 950px) {
    background-color: transparent;
    display: inline;
    padding: 0px;
  }
`;

const SignatureSpan = styled.span`
  font-weight: normal;
  padding-left: 10px;

  @media (max-width: 950px) {
    display: none;
  }
`;

const SellHeaderText = () => {
  return (
    <NewsLine>
      <NewsText>
        <TextSpan>
          "Get the highest offer for your unwanted card with a really fast
          payout"
          <SignatureSpan>- Peter Imbimbo</SignatureSpan>
        </TextSpan>
      </NewsText>
    </NewsLine>
  );
};

export default SellHeaderText;

import React from "react";
import { getSellCardsInfo } from "@modules/Sell/selector";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const MailMessageContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  font-size: 14px;
  line-height: 1.71;
  color: #1f2021;
  text-align: center;
  height: 64px;
  border-radius: ${props => props.borderRadius};
`;

const InnerMailMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 710px) {
    max-width: 90%;
    margin: 0 auto;
  }
`;

const IconSpan = styled.img`
  margin-right: 16px;

  @media (max-width: 500px) {
    display: none;
  }
`;

const MailInStatusBar = ({ borderRadius, backgroundColor, isCheckout }) => {
  const OrderInfo = useSelector(state =>
    getSellCardsInfo({
      merchants: state.merchantsSell,
      cards: state.cards
    })
  );

  return (
    <>
      {OrderInfo.isMailIn && (
        <MailMessageContainer
          borderRadius={borderRadius}
          backgroundColor={backgroundColor}
        >
          <InnerMailMessage>
            {isCheckout
              ? OrderInfo.isMultipleCards
                ? "Please note: all of these cards need to be mailed to us"
                : "Please note: this card needs to be mailed to us"
              : OrderInfo.messageText}
          </InnerMailMessage>
        </MailMessageContainer>
      )}
    </>
  );
};

export default MailInStatusBar;

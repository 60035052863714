import React from "react";
import styled from "@emotion/styled";
import { FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const Span = styled.span`
  position: relative;
`;

const Image = styled.img`
  margin: 0;
`;

const InnerSpan = styled.span`
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 30px;
  box-shadow: 0 6px 12px 0 rgba(31, 32, 33, 0.1);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(119, 121, 132, 0.5);
  cursor: pointer;
`;

const DeleteCardTrashCan = ({
  setClass,
  img,
  handleClick,
  slug,
  handleTouchStart,
  handleTouchEnd
}) => (
  <Span className={setClass}>
    {slug ? (
      <Link to={`/buy-gift-cards/${slug}/`}>
        <Image src={img} className="delete-trash-image" alt="delete button" />
      </Link>
    ) : (
      <Image src={img} className="delete-trash-image" alt="delete button" />
    )}

    <InnerSpan
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onClick={handleClick}
      className="delete-trash-inner-span"
    >
      <FaTrashAlt size={10.5} />
    </InnerSpan>
  </Span>
);

export default DeleteCardTrashCan;

import React from "react";
import styled from "@emotion/styled";
import SellFlowStatus from "./SellFlowStatus";
import { ClearedStyleRouterLink } from "@modules/Components";
import { IoIosArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SellHeaderBackButton from "./SellHeaderBackButton";
import SellHeaderText from "./SellHeaderText";

const HeaderContainer = styled.div`
  height: 408px;
  border-radius: 0px 0px 280px 0px;
  background-color: #0d0236;
  background-image: url(https://cdn.cardcash.com/home/images/other/element-background-group.svg) !important;
  background-repeat: no-repeat;
  text-align: center;
  background-position: left bottom;
  color: #ffffff;
  padding-top: 90px;

  @media (max-width: 1300px) {
    background-image: none !important;
  }
  @media (max-width: 1150px) {
    padding-top: ${props => props.smallDesktopPaddingTop};
    height: 310px;
    border-radius: 0px 0px 160px 0px;
  }
  @media (max-width: 767px) {
    height: 325px;
    border-radius: 0px 0px 100px 0px;
    padding-top: 50px;
  }
  @media (max-width: 600px) {
    position: relative;
  }
  @media (max-width: 350px) {
    padding-top: 45px;
    height: 303px;
  }
`;

const HeaderText = styled.h1`
  font-size: 60px;
  margin-top: 25px;
  margin-bottom: 25px;

  @media (max-width: 1150px) {
    font-size: 36px;
    margin-top: 16px;
  }
  @media (max-width: 850px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 600px) {
    max-width: 354px;
    margin: 0 auto;
    margin-top: 23px;
    position: static;
  }
  @media (max-width: 450px) {
    font-size: 32px;
    max-width: 314px;
  }
  @media (max-width: 395px) {
    font-size: 28px;
  }
  @media (max-width: 365px) {
    max-width: 79%;
  }
  @media (max-width: 350px) {
    font-size: 26px;
  }
`;

const BackSpan = styled(ClearedStyleRouterLink)`
  @media (min-width: 851px) {
    display: none;
  }
  position: absolute;
  left: 20px;
  margin-right: 5px;

  @media (max-width: 670px) {
    left: 10px;
  }
  @media (max-width: 625px) {
    left: 5px;
  }
  @media (max-width: 600px) {
    top: 41%;
    left: 5%;
  }
  @media (max-width: 475px) {
    left: 2%;
  }
  @media (max-width: 450px) {
    top: 40.5%;
  }
  @media (max-width: 395px) {
    top: 40.1%;
  }
  @media (max-width: 385px) {
    top: 38.1%;
  }
  @media (max-width: 350px) {
    top: 38.7%;
  }
`;

const BackArrow = styled(IoIosArrowBack)`
  color: rgba(255, 255, 255, 0.5);
  font-size: 34px;

  @media (max-width: 475px) {
    font-size: 32px;
  }
  @media (max-width: 450px) {
    font-size: 30px;
  }
`;

const SellHeaderWithStatus = ({ handleContinueButtonClick, disabledState }) => {
  const location = useLocation();
  const bannersList = useSelector(state => state.banners.bannersList);

  const flowStep = location.pathname.startsWith("/sell-gift-cards") ? 1 : 2;

  return (
    <HeaderContainer smallDesktopPaddingTop={bannersList ? "56px" : "35px"}>
      {flowStep === 2 ? <SellHeaderBackButton /> : <SellHeaderText />}
      <HeaderText>
        {flowStep === 2 && (
          <BackSpan to="/sell-gift-cards/">
            <BackArrow />
          </BackSpan>
        )}
        Sell your unwanted gift cards
      </HeaderText>
      <SellFlowStatus
        flowStep={flowStep}
        handleContinueButtonClick={handleContinueButtonClick}
        disabledState={disabledState}
      />
    </HeaderContainer>
  );
};

export default SellHeaderWithStatus;

import { createSelector } from "reselect";

const merchants = ({ merchants }) => merchants;
const cards = ({ cards }) => cards;
const trade = ({ trade }) => trade;
const cart = ({ cart }) => cart;

export const getSellCardsInfo = createSelector(
  merchants,
  cards,
  (merchantsParam, cardsParam) => {
    const orderInfo = {
      isMailIn: false,
      messageText: "",
      isMultipleCards: false
    };
    if (Object.keys(cardsParam).length > 0) {
      const cardArray = Object.entries(cardsParam);

      orderInfo.messageText = `📨 ${
        cardArray.length > 1 ? "These cards need" : "This card needs"
      } to be mailed to our New Jersey office. (Hey, 🐌 snail mail's still cool.)`;

      if (cardArray.length > 1) {
        orderInfo.isMultipleCards = true;
      }

      cardArray.forEach(item => {
        const mailIn = merchantsParam[item[1].id]?.submissionMethod;
        if (mailIn === "mail-in") {
          orderInfo.isMailIn = true;
        }
      });
    }
    return orderInfo;
  }
);

export const getSellCardsArray = createSelector(
  merchants,
  cards,
  trade,
  (merchantsParam, cardsParam, tradeParam = 0) => {
    const orderArray = [];
    if (Object.keys(cardsParam).length > 0) {
      const cardArray = Object.entries(cardsParam);
      cardArray.forEach(item => {
        const sellObj = {
          img: merchantsParam[item[1].id]?.image,
          name: merchantsParam[item[1].id]?.name,
          offerValue:
            +item[1].cashValue + (tradeParam / 100) * +item[1].cashValue
        };
        orderArray.push(sellObj);
      });
    }
    return orderArray;
  }
);

export const getSellCardsTotals = createSelector(
  cards,
  cart,
  (cardsParam, cartParam) => {
    const orderSummary = {};
    if (
      cardsParam &&
      Object.keys(cardsParam)?.length > 0 &&
      cartParam &&
      cartParam.cardIds?.length > 0
    ) {
      let total = Object.keys(cardsParam).reduce((memo, value) => {
        return Number(memo) + Number(cardsParam[value].cashValue);
      }, 0);

      if (cartParam.tradeBonus) {
        total += (cartParam.tradeBonus / 100) * total;
      }
      orderSummary.total = total;
    }
    return orderSummary;
  }
);

import React from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const FlowStatus = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  text-align: left;
  justify-content: center;
  grid-gap: 55px;

  @media (max-width: 710px) {
    grid-gap: 35px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const FlowStep = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  grid-gap: 13px;
  cursor: ${props => props.cursorStatus};
`;

const FlowNumber = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.currentStep ? "rgba(255, 255, 255, 0.2)" : ""};
  border: ${props =>
    props.currentStep ? "" : " solid 1px rgba(255, 255, 255, 0.2)"};
`;

const flow = [
  {
    id: 1,
    text: "Get Quote",
    link: "/sell-gift-cards/"
  },
  {
    id: 2,
    text: "Submit Cards",
    link: "/step-two/"
  },
  {
    id: 3,
    text: "Get Paid 💰",
    link: "/payout/"
  }
];
const SellFlowStatus = ({
  flowStep,
  handleContinueButtonClick,
  disabledState
}) => {
  const history = useHistory();
  const cards = useSelector(state => state.cards);

  const handleClick = (link, id) => {
    if (id === 1) {
      history.push(link);
    } else if (id === 2) {
      if (Object.keys(cards)?.length < 1) {
        return;
      } else {
        history.push(link);
      }
    } else {
      if (!disabledState && flowStep > 1) {
        handleContinueButtonClick();
      }
    }
  };

  const getCursorStatus = id => {
    if (id === 1) {
      return "pointer";
    } else if (id === 2) {
      if (Object.keys(cards)?.length < 1) {
        return "not-allowed";
      } else {
        return "pointer";
      }
    } else {
      return disabledState || flowStep === 1 ? "not-allowed" : "pointer";
    }
  };

  return (
    <FlowStatus>
      {flow.map((item, index) => (
        <FlowStep
          key={index}
          onClick={() => handleClick(item.link, item.id)}
          cursorStatus={getCursorStatus(item.id)}
        >
          <FlowNumber currentStep={flowStep === item.id}>{item.id}</FlowNumber>
          <div>{item.text}</div>
        </FlowStep>
      ))}
    </FlowStatus>
  );
};

export default SellFlowStatus;
